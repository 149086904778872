<template>
  <card class="p-3 col-lg-8 bg-secondary">
    <h1 class="page-header mb-lg-3 card-title">Create your account</h1>
    <ValidationObserver v-slot="{ invalid, validate }" ref="formValidator">
      <form @submit.prevent="validate().then(submit)" class="needs-validation">
        <div class="row d-flex justify-content-center">
          <div class="mx-3">
            <div class="mb-4">
              <b-row>
                <b-col lg="12">
                  <base-input
                    type="text"
                    name="full name"
                    placeholder="Full Name"
                    rules="required|alpha_spaces"
                    v-model="name"
                    group
                    alternative
                  ></base-input>
                </b-col>
                <b-col lg="12">
                  <base-input
                    type="email"
                    placeholder="Email Address"
                    name="email"
                    v-model="email"
                    rules="required|email"
                    group
                    alternative
                  ></base-input>
                </b-col>
                <b-col lg="12">
                  <base-input
                    :type="show_password ? 'text' : 'password'"
                    placeholder="Password"
                    v-model="password"
                    :rules="{ required: true, regex: passwordRegex }"
                    name="password"
                    ref="password"
                    autocomplete="off"
                    @input="checkPassword"
                    group
                    alternative
                  >
                    <template slot="append">
                      <i @click="show_password = !show_password"
                         class="eye-icon"
                         :class="show_password === true ? 'fas fa-eye-slash' : 'fas fa-eye'" aria-hidden="true"></i>
                    </template>
                  </base-input>
                  <transition name="slide">
                    <div class="password-helper gray-600-color small-text" v-show="show_helper">
                      <b-row>
                        <b-col lg="6">
                          <li class="password-rule" v-bind:class="{ 'is-valid': contains_twelve_characters }">
                            <input type="checkbox" disabled v-bind:checked="contains_twelve_characters" />
                            <span>12 Characters</span>
                          </li>
                        </b-col>
                        <b-col lg="6">
                          <li class="password-rule" v-bind:class="{ 'is-valid': contains_number }">
                            <input type="checkbox" disabled v-bind:checked="contains_number" />
                            <span>Includes Number</span>
                          </li>
                        </b-col>
                      </b-row>
                      <b-row>
                        <b-col lg="6">
                          <li class="password-rule" v-bind:class="{ 'is-valid': contains_uppercase }">
                            <input type="checkbox" disabled v-bind:checked="contains_uppercase" />
                            <span>Includes Uppercase</span>
                          </li>
                        </b-col>
                        <b-col lg="6">
                          <li class="password-rule" v-bind:class="{ 'is-valid': contains_special_character }">
                            <input type="checkbox" disabled v-bind:checked="contains_special_character" />
                            <span style="margin-left: 5px">Includes Special Character</span>
                          </li>
                        </b-col>
                      </b-row>
                    </div>
                  </transition>
                </b-col>
              </b-row>
            </div>
            <div class="rights-section mb-4 px-1">
              <validate-checkbox
                v-model="terms"
                class="mb-1"
                name="Terms of Service"
                :rules="{ required: { allowFalse: false } }"
              >
                I agree to Keeyora’s
                <a href="https://www.rectxt.com/terms-of-service" target="_blank">
                  <u>Terms of Service</u></a>
              </validate-checkbox>
              <validate-checkbox
                v-model="policy"
                class="mb-1"
                :rules="{ required: { allowFalse: false } }"
                name="Privacy Policy"
              >
                I accept Keeyora’s use of my data for the service and anything
                else described in the
                <a href="https://www.rectxt.com/privacy-policy" target="_blank">
                  <u>Privacy Policy</u></a>
              </validate-checkbox>
            </div>
            <div class="layout-button">
              <div class="form-common-errors small-text mb-2">
                {{ registerFormErrors }}
              </div>
              <button
                type="submit"
                class="btn btn-outline btn-primary btn-block font-weight-bold"
                :disabled="invalid"
              >
                Create Account
              </button>
            </div>
            <div class="microsoft-sign d-flex align-items-center flex-column">
              <p @click="microsoftSign = !microsoftSign">
                <u>{{
                    !microsoftSign
                      ? 'Continue with Microsoft?'
                      : 'Hide Microsoft sign in'
                  }}</u>
              </p>
              <template v-if="microsoftSign">
                <MicrosoftLogo/>
              </template>
              <div class="options mt-3">
                <p class="font-weight-bold">
                  Already have an account?
                  <router-link :to="{ name: 'Login' }">Sign in</router-link>
                </p>
              </div>
            </div>
          </div>
        </div>
      </form>
    </ValidationObserver>
  </card>
</template>
<script>
import {mapActions} from 'vuex';
import MicrosoftLogo from '/src/components/App/UI/MicrosoftLogo.vue';
import {LOGIN_REQUEST} from '/src/store/storeActions';
import ValidateCheckbox from '../../components/App/controls/ValidateCheckbox.vue';

export default {
  components: {
    MicrosoftLogo,
    ValidateCheckbox,
  },
  data() {
    return {
      email: '',
      password: '',
      name: '',
      company: '',
      microsoftSign: false,
      terms: false,
      policy: false,
      validator: null,
      show_password: false,
      show_helper: false,
      password_length: 0,
      contains_twelve_characters: false,
      contains_number: false,
      contains_uppercase: false,
      contains_special_character: false,
      valid_password: false,
      // eslint-disable-next-line
      passwordRegex: /^(?=.*[0-9])(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[A-Z])[a-zA-Z0-9!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]{12,}$/,
    };
  },
  computed: {
    getFormErrors: function() {
      const errors = [];
      if (this.validator) {
        Object.entries(this.validator.errors).forEach(([key, value]) => {
          if (value.length > 0) {
            errors.push(...value);
          }
        });
      }
      return errors;
    },

    registerFormErrors: function() {
      return (this.getFormErrors.length) > 0 ?
        'Please fill the fields correctly' :
        null;
    },
  },
  methods: {
    ...mapActions([
      LOGIN_REQUEST,
    ]),
    async submit() {
      const fullNameArr = this.name.split(' ');
      const data = {
        currentRequest: 'register',
        requestData: {
          email: this.email,
          password: this.password,
          name: this.name,
          firstName: fullNameArr[0],
          lastName: fullNameArr[1],
          company: this.company,
        },
      };
      this.LOGIN_REQUEST(data);
    },
    checkPassword() {
      this.password_length = this.password.length;
      const format = /[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/;

      this.contains_twelve_characters = this.password_length >= 12;
      this.contains_number = /\d/.test(this.password);
      this.contains_uppercase = /[A-Z]/.test(this.password);
      this.contains_special_character = format.test(this.password);

      this.valid_password = this.contains_twelve_characters === true &&
        this.contains_special_character === true &&
        this.contains_uppercase === true &&
        this.contains_number === true;
      this.show_helper = true;
    },
  },
  mounted: function() {
    this.validator = this.$refs.formValidator;
  },
};
</script>
<style scoped lang="scss">
@import "@/assets/sass/lbd/mixins/_layout.scss";
@import "@/assets/sass/custom/_app-vars.scss";
.login-logo {
  width: 150px;
}

.grid {
  display: grid;
  height: fit-content;
}

.self-end {
  justify-self: end;
}

.layout-button {
  @include respond-to(sm) {
    width: 100% !important;
  }
  @include respond-to(xs) {
    width: 100% !important;
  }
}

.microsoft-sign {
  margin-top: 20px;
  cursor: pointer;
}

.rights-section a {
  color: $primary-border-color;
}

.eye-icon:hover {
  color: $primary-border-color;
}

.is-valid {
  color: $primary-color;
  font-weight: 600
}

.is-valid:before {
  width: 100%;
}

.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to, .slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter, .slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
.password-rule {
  list-style-type: none; /* Remove bullet points */
  display: flex;
  align-items: center; /* Align items to the center */
}

.password-rule input[type="checkbox"] {
  appearance: none; /* Remove default styles */
  width: 22px; /* Fixed width */
  height: 22px; /* Fixed height */
  margin-right: 4px; /* Margin between checkbox and text */
  display: inline-block;
  position: relative;
  background: none; /* Remove the box background */
  border: none; /* Remove the border */
}

.password-rule input[type="checkbox"]:checked::before {
  content: '✔';
  color: $primary-color;
  font-size: 16px; /* Adjust the font size to fit the checkbox */
  position: absolute;
  top: 25%;
  left: 25%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.password-rule span {
  line-height: 1.2; /* Adjust line height to align text properly */
}

</style>
